import { CommonConstants } from "../constants/common-constants";
import { DefaultResultsPerPage } from "../enums/common-enum";

export class CommonDataModel {

    public pageSizes: any = [10, 20, 30];

    public permissionDataModel: any = {
        role: '',
        isAdministrator: false,
        hydro: false,
        budget: false,
        cad: false,
        is_doc_manager: false
    }

    public gridPayload: any = {
        user_id: '',
        grid_name: '',
        filter_value: '',
        search_key: '',
        search_value: '',
        sort_by: '',
        sort_order: 'asc',
        skip_count: 0,
        per_page: DefaultResultsPerPage.value,
        filter_key: '',
        is_archived: false,
        timezone: new Date().getTimezoneOffset().toString()
    }

    public gridFilterModel: any = {
        user_id: '',
        grid_name: '',
        filter_value: '',
        search_key: '',
        sort_by: '',
        sort_order: 'asc',
        column_fields: [],
        grouping: [],
        is_archived: false,
        timezone: new Date().getTimezoneOffset().toString()
    }

    public virtualScrollPayload: any = {
        skip_count: 0,
        per_page: DefaultResultsPerPage.value,
        timezone: new Date().getTimezoneOffset().toString()
    }

    public gridSelectionColumnModel: any = {
        field: 'id',
        title: 'Selection Id',
        template: null,
        width: 250,
        reorder: 1,
        hidden: true
    };

    public leadGridFilterModel: any = {
        my_lead: false,
        open: true,
        close: false
    }

    public requestFormPayload: any = {
        lead_id: '',
        leadline_id: '',
        document_type: '',
        required_at: '',
        template: '',
        note: '',
        user_ids: [] as any[],
        users: [] as any[],
        model: '',
        price_type: '',
        level: '',
        is_pricing_needed: false,
        pricing_data: {
            user_ids: [] as any[],
            price_type: '',
            required_time: '',
            note: ''
        }
    }

    public reAssignUserFormPayload: any = {
        re_assigned_to: '',
        re_assigned_date: '',
        re_assigned_comment: '',
        tagged_user_ids: []
    }

    public reviseDueDateFormPayload: any = {
        comment: '',
        due_date: '',
    }

    public approveRequestFormPayload: any = {
        body: '',
        users: [] as any[]
    }

    public uploadFileModel: any = {
        file: null,
        url: null,
        fileName: null,
        fileSize: null,
        isFileValid: false
    }

    public fileModel: any = {
        url: null,
        fileName: null
    }

    public uploadSizingFormPayload: any = {
        id: '',
        doc_req_id: '',
        headLoss: '',
        is_approved: '',
        model: '',
        oldFile: '',
        oldFileName: '',
        peakFlow: '',
        blindingPercentage: '',
        velocityThroughTheGrid: '',
        washwaterAmountGpm: '',
        washwaterAmountPsi: '',
        file: {
            url: null,
            fileName: null
        },
    }

    public uploadDrawingFormPayload: any = {
        doc_req_id: '',
        is_approved: '',
        model: '',
        oldFile: '',
        oldFileName: '',
        breakout_files: [] as any[],
        breakout_files_old: [] as any[],
        file: {
            fileName: '',
            url: ''
        }
    }

    public uploadDocFormPayload: any = {
        model: '',
        file: {
            fileName: '',
            url: ''
        }
    }

    public addCommentFormPayload: any = {
        body: '',
        users: [] as any[],
        file: {
            fileName: '',
            url: ''
        }
    }

    public issueFormPayload: any = {
        lead_id: 0,
        leadline_id: 0,
        responder_id: '',
        due_date_time: '',
        subject: '',
        issue_body: '',
        file: {
            url: null,
            fileName: null
        },
        users: [],
        isRequestLoading: true,
        leadline_description: '',
        call_id: '',
        call_line_id: ''
    }

    public issueFormLoaderModel: any = {
        isFormLoading: false,
        isLeadLineLoading: false,
        isResponderLoading: false
    }

    public resolveIssueFormPayload: any = {
        body: '',
        users: [] as any[],
        resolved: true,
        file: {
            fileName: '',
            url: ''
        }
    }

    public reportsGridPayload: any = {
        sort_order: 'asc',
        sort_by: '',
        timezone: new Date().getTimezoneOffset().toString(),
        per_page: DefaultResultsPerPage.value,
        skip_count: 0
    }

    public gridFilterFormPayload: any = {
        grid_name: '',
        filter_name: '',
        is_default_filter: false,
        sort_by: '',
        sort_order: 'asc',
        column_fields: [],
        grouping: [],
        filters: [],
        search_key: '',
        search_value: '',
        timezone: new Date().getTimezoneOffset().toString(),
    }

    public dropDownKeyValueModel: any = {
        key: '',
        value: ''
    }

    public pricingTypeDataSource: any = [
        {
            key: '1',
            value: 'Firm Pricing'
        },
        {
            key: '2',
            value: 'Budget Pricing'
        }
    ]

    public unitsDataSource: any = [
        {
            key: 'metric',
            value: 'Metric'
        },
        {
            key: 'imperial',
            value: 'Imperial'
        }
    ];

    public drawingLevelDataSource: any = [
        {
            key: '1',
            value: 'Level 1'
        },
        {
            key: '2',
            value: 'Level 2'
        },
        {
            key: '3',
            value: 'Level 3'
        }
    ];

    public reportDataSource: any = [
        {
            key: '1',
            value: 'Hydraulic Report'
        },
        {
            key: '2',
            value: 'Pricing Report'
        }
    ];

    public turnAroundDataSource: any = [
        {
            key: 2,
            value: 'Standard (2 business days)'
        },
        {
            key: 1,
            value: 'Urgent (1 business day)'
        }
    ];

    public notificationTitleModel: any = [
        {
            name: 'sizing_request',
            title: CommonConstants.notificationTooltipConstants.sizingRequest
        },
        {
            name: 'sizing_update',
            title: CommonConstants.notificationTooltipConstants.sizingUpdate
        },
        {
            name: 'sizing_comment',
            title: CommonConstants.notificationTooltipConstants.sizingComment
        },
        {
            name: 'drawing_request',
            title: CommonConstants.notificationTooltipConstants.drawingRequest
        },
        {
            name: 'drawing_update',
            title: CommonConstants.notificationTooltipConstants.drawingUpdate
        },
        {
            name: 'drawing_comment',
            title: CommonConstants.notificationTooltipConstants.drawingComment
        },
        {
            name: 'budget_request',
            title: CommonConstants.notificationTooltipConstants.budgetRequest
        },
        {
            name: 'budget_update',
            title: CommonConstants.notificationTooltipConstants.budgetUpdate
        },
        {
            name: 'budget_comment',
            title: CommonConstants.notificationTooltipConstants.budgetComment
        },
        {
            name: 'issue_request',
            title: CommonConstants.notificationTooltipConstants.issueRequest
        },
        {
            name: 'issue_comment',
            title: CommonConstants.notificationTooltipConstants.issueComment
        }
    ];

    public contactFormLoaderModel: any = {
        isFormLoading: false,
        isIndustryTypeLoading: false,
        isCompanyListLoading: false,
    }

    public contactFormPayload: any = {
        id: null,
        contact_id: '',
        email: '',
        firstname: '',
        lastname: '',
        jobtitle: '',
        industry_type: '',
        phone: '',
        alt_phone_number: '',
        mobilephone: '',
        fax: '',
        notes: '',
        is_lds_updated: 0,
        is_deleted: 0,
        updated_by: '',
        created_by: '',
        association_changed: false,
        association: [],
        created_at: '',
        updated_at: ''
    }

    public companyFormLoaderModel: any = {
        isFormLoading: false,
        isIndustryTypeLoading: false,
        isHubspotOwnerLoading: false,
        isContactListLoading: false
    }

    public companyFormPayload: any = {
        id: null,
        company_id: '',
        domain: '',
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        phone: '',
        alt_phone_number: '',
        fax_number: '',
        email_address: '',
        industry_type: '',
        description: '',
        is_deleted: 0,
        is_lds_updated: 0,
        hubspotOwnerId: '',
        updated_by: '',
        created_by: '',
        association_changed: false,
        association: [],
        created_at: '',
        updated_at: '',
        project_shipping_address: [],
        shipping_address_changed: false,
        updated_shipping_address: []
    }

    public contactSelectionFilterModel: any = [
        {
            key: 'is_archived',
            text: 'Archived',
            isSelected: false
        }
    ];

    public companySelectionFilterModel: any = [
        {
            key: 'is_archived',
            text: 'Archived',
            isSelected: false
        }
    ];

    public contactCompanyDropdownSource: any = [
        {
            value: 'search',
            text: 'Search'
        },
        {
            value: 'date',
            text: 'Date Range'
        }
    ];

    public systemAnnouncementFormPayload: any = {
        id: null,
        subject_line: "",
        description: ""
    }

    public announcementPayload: any = {
        id: null,
        subject_line: "",
        description: "",
        created_by: "",
        updated_by: ""
    }

    public leadFormPayload: any = {
        dealname: '',
        long_description: '',
        company_id: null,
        month_of_budget_year_end: '',
        marketing_program: '',
        referred_by: null,
        bid_date: null,
        deal_stage_date: null,
        deal_closed_date: null,
        expiration_date: null,
        business_development: null,
        deal_owner: '',
        deal_stage: '',
        shipping_address_id: null,
        rep_company_id: null
    }

    public leadStageDataSource: any = [
        {
            value: 'UL',
            text: 'Unqualified Lead'
        },
        {
            value: 'PREL',
            text: 'Preliminary Design'
        },
        {
            value: 'DD',
            text: 'Detailed Design'
        },
        {
            value: 'BID',
            text: 'Bidding'
        },
        {
            value: 'ABR',
            text: 'Awaiting Bid Results'
        },
        {
            value: 'WIN',
            text: 'Win'
        },
        {
            value: 'LOST',
            text: 'Bid Lost to Competitor'
        },
        {
            value: 'DEAD',
            text: 'No Bid Project Dead'
        }
    ]

    public editLeadFormPayload: any = {
        id: 0,
        dealname: '',
        long_description: '',
        address: '',
        company_id: null,
        month_of_budget_year_end: '',
        marketing_program: '',
        referred_by: null,
        bid_date: null,
        deal_owner_id: null,
        deal_stage_date: null,
        deal_stage: '',
        deal_closed_date: null,
        expiration_date: null,
        business_development: null,
        shipping_address_id: null,
        stakeholder: [],
        status: '',
        closedate: null,
        closed_reason: '',
        association_changed: false,
        closed_by: '',
        deal_id: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        rep_company_id: null
    }

    public editProjectShippingDetails: any = {
        leadId: '',
        company_id: '',
        project_shipping_address: ''
    }
}
