import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { LeadService } from 'src/app-core/services/lead-service.service';
import { CommonSettings } from 'src/app-core/settings/common-settings';

@Component({
    selector: 'app-create-lead-form',
    templateUrl: './create-lead-form.component.html'
})

export class CreateLeadFormComponent implements OnInit {

    commonSettings = new CommonSettings();
    commonDataModel = new CommonDataModel();
    leadFormPayload = _.cloneDeep(this.commonDataModel.leadFormPayload);
    leadStageDataSource = _.cloneDeep(this.commonDataModel.leadStageDataSource);
    formType: string = '';
    cityState: string = '';
    country: string = '';
    address: string = '';
    zipcode: string = '';
    isFormLoading = false;
    companyFieldEmpty = false;
    loggedUserData: any;
    selectedCompany: any;
    selectedCompanyId: any;
    filteredCompanies: any[] = [];
    budgetYearEndDataList: any[] = [];
    marketingBudgetYearDataList: any[] = [];
    companiesDataList: any[] = [];
    repCommissionLevelDataList: any[] = [];
    salesPersonConfidenceDataList: any[] = [];
    businessDevelopmentDataList: any[] = [];
    shippingAddressDataList: any[] = [];
    minDate: Date = new Date();
    dateFormat = this.commonSettings.dateFormat;
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        private dialogRef: MatDialogRef<CreateLeadFormComponent>,
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private leadService: LeadService,
        private customToastrService: CustomToastrService,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            this.formType = data?.formType;
        }
    }

    ngOnInit(): void {
        this.onLoadItems();
    }

    onLoadItems(): void {
        this.isFormLoading = true;
        this.leadService.getLeadFields()
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.loggedUserData = this.commonMethod.getLoggedUserData();
                this.leadFormPayload.deal_owner = this.loggedUserData.name;
                this.companiesDataList = response.data.dd_fields.companies;
                this.budgetYearEndDataList = response.data.dd_fields.month_of_budget_year_end;
                this.marketingBudgetYearDataList = response.data.dd_fields.marketing_program;
                this.businessDevelopmentDataList = response.data.dd_fields.business_development;
            });
    }

    onCompanyChange(event: any) {
        this.isFormLoading = true;
        const selectedCompany = this.companiesDataList.find(company => company.id === event);
        this.cityState = selectedCompany ? `${selectedCompany.city}, ${selectedCompany.state}` : '';
        this.country = selectedCompany ? selectedCompany.country : '';
        this.address = selectedCompany ? selectedCompany.address : '';
        this.zipcode = selectedCompany ? selectedCompany.zip : '';
        this.leadFormPayload.shipping_address_id = '';
        this.commonService.getShippingAddress(this.leadFormPayload.company_id)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.shippingAddressDataList = response.data;
            });
    }

    onFormSubmit() {
        this.isFormLoading = true;
        let payload = _.cloneDeep(this.leadFormPayload);
        payload.bid_date = this.commonMethod.convertDate(this.leadFormPayload.bid_date);
        payload.deal_stage_date = this.commonMethod.convertDate(this.leadFormPayload.deal_stage_date);
        payload.deal_closed_date = this.commonMethod.convertDate(this.leadFormPayload.deal_closed_date);
        payload.expiration_date = this.commonMethod.convertDate(this.leadFormPayload.expiration_date);
        this.commonService.postLead(payload)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.customToastrService.success(response?.message);
                this.dialogRef.close({ confirmResult: true });
            });
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}
