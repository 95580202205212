export class LeadDataModel {

    public leadSideNavBarModel: any = [
        {
            label: 'Dashboard',
            name: 'dashboard',
            route: 'dashboard',
            isIcon: false,
            isActive: true,
            isCompleted: false
        },
        {
            label: 'LeadLine Form',
            name: 'leadLineForm',
            route: 'lead-line',
            isIcon: false,
            isActive: true,
            isCompleted: false
        },
        {
            label: 'Sizing',
            name: 'sizing',
            route: 'sizing-list',
            isIcon: true,
            isActive: false,
            isCompleted: false
        },
        {
            label: 'Drawing',
            name: 'drawing',
            route: 'drawing-list',
            isIcon: true,
            isActive: false,
            isCompleted: false
        },
        {
            label: 'Pricing',
            name: 'pricing',
            route: 'pricing-list',
            isIcon: true,
            isActive: false,
            isCompleted: false
        },
        {
            label: 'Handoff Report',
            name: 'engineerReport',
            route: 'handoff-report',
            isIcon: true,
            isActive: false,
            isCompleted: false
        },
        {
            label: 'Change Log',
            name: 'changeLog',
            route: 'change-log-list',
            isIcon: false,
            isActive: false,
            isCompleted: false
        },
    ];

    public memoFormPayload: any = {
        lead_id: '',
        comment: ''
    }

    public leadLineCopyPayload: any = {
        leadId: null,
        leadlineId: null
    }

    public leadLineActivatePayload: any = {
        leadId: null,
        leadline: [],
        isActive: false
    }

    public detailsInfoPayload: any = {
        deal_owner_id: '',
        rep_company_id: null
    }

    public leadSummaryPayload: any = {
        deal_id: '',
        dealname: '',
        long_description: '',
        business_development: '',
        shipping_address_id: ''
    }

    public statusInformationPayload: any = {
        deal_id: '',
        status: '',
        deal_stage_date: '',
        deal_stage: '',
        deal_closed_date: '',
        closed_reason: '',
        bid_date: '',
        closed_by: ''
    }

    public editCompanyDetailsPayload: any = {
        company_id: '',
        deal_id: '',
        city: '',
        state: '',
        country: '',
        phone: '',
        zip: '',
        address: '',
        shipping_address_id: ''
    }

}
