<div class="details-box-wrapper">
    <div class="text-wrapper">
        <div class="row">
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.deal_owner_name | valueCheck}}</div>
                    <div class="label">Lead Owner</div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">${{ leadDetailsModel?.amount ?? 0 }}</div>
                    <div class="label">Lead Amount</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.rep_company_name | valueCheck}}</div>
                    <div class="label">Rep Company</div>
                </div>
            </div>
        </div>
    </div>
</div>