import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { finalize, Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { ResolveIssueFormComponent } from '../resolve-issue-form/resolve-issue-form.component';

@Component({
    selector: 'app-all-comments-list',
    templateUrl: './all-comments-list.component.html',
    styleUrls: ['./all-comments-list.component.scss']
})
export class AllCommentsListComponent implements OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();
    commonDataModel = new CommonDataModel();
    virtualScrollPayload: any;
    documentType: any;
    requestId: any;
    loggedUserId: any;
    isFormLoading = false;
    commentsList: any[] = [];
    currentPage = 1;
    lastPage = 1;
    paramLeadId: any;
    paramRequestId: any;
    selectedParentIndex: any;
    selectedSupportDocumentId: any;
    selectedSupportDocumentIndex: any;
    activeUserSourceList: any[] = [];
    isLeadLineActive = true;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set type(value: string) {
        this.documentType = value;
    }

    @Input() set dropDownList(data: any) {
        if (data?.length) {
            this.activeUserSourceList = data;
        }
    }

    constructor(
        private matDialog: MatDialog,
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private activeRoute: ActivatedRoute,
        private customToastrService: CustomToastrService) {
        let virtualScrollPayload = _.cloneDeep(this.commonDataModel.virtualScrollPayload);
        this.virtualScrollPayload = Object.assign(virtualScrollPayload, { filter_value: '' });
        this.loggedUserId = this.commonMethod.getUserId();
    }

    ngOnInit() {
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            this.paramLeadId = params.get('leadId');
            this.paramRequestId = params.get('requestId');
        }));
        this.componentSubscriptions.add(
            this.commonMethod.getLoadAllCommentsValue.subscribe((data: string) => {
                this.requestId = data;
                this.onLoadItems();
            })
        );
        this.componentSubscriptions.add(
            this.commonMethod.getDeleteConformationValue.subscribe((data: boolean) => {
                if (data) {
                    this.deleteSupportDocument();
                }
            })
        );
        this.componentSubscriptions.add(
            this.commonMethod.getLeadlineStatus.subscribe((data: boolean) => {
                this.isLeadLineActive = data;
            })
        );
    }

    onLoadItems(): void {
        if (this.requestId) {
            this.isFormLoading = true;
            switch (this.documentType) {
                case CommonConstants.gridNameConstants.recentActivity:
                    this.commonService.postRecentActivityRecords(this.virtualScrollPayload).subscribe(response => {
                        if (response.data) {
                            this.setCommentsList(response.data);
                        }
                    });
                    break;
                case CommonConstants.gridNameConstants.issuesGrid:
                    if (this.requestId === this.paramLeadId) {
                        this.commonService.postIssueComments(this.requestId, this.virtualScrollPayload).subscribe(response => {
                            if (response.data) {
                                this.setCommentsList(response.data);
                            }
                        });
                    } else {
                        this.commonService.postIssueCommentsById(this.paramLeadId, this.requestId, this.virtualScrollPayload).subscribe(response => {
                            if (response.data) {
                                this.setCommentsList(response.data);
                            }
                        });
                    }
                    break;
                default:
                    this.commonService.postAllCommentsRecords(this.requestId, this.documentType, this.virtualScrollPayload).subscribe(response => {
                        if (response.data) {
                            this.setCommentsList(response.data);
                        }
                    });
                    break;
            }
        }
    }

    setCommentsList(data: any): void {
        this.currentPage = data?.current_page;
        this.lastPage = data?.last_page;
        this.commentsList = this.virtualScrollPayload.skip_count === 0 ? data.data : this.commentsList.concat(...data.data);
        if (this.commentsList?.length) {
            _.filter(this.commentsList, ((list: any, index: number) => {
                list.bodyText = '';
                list.uploadFile = _.cloneDeep(this.commonDataModel.fileModel);
                _.filter(list.comments, ((item: any) => {
                    item.isExpand = false;
                    item.isEditComment = false;
                    item.uploadFile = _.cloneDeep(this.commonDataModel.fileModel);
                }));
                if (this.commentsList.length === (index + 1)) {
                    this.isFormLoading = false;
                }
            }));
        } else {
            this.isFormLoading = false;
        }
    }

    onScrollDown() {
        if (this.commentsList?.length > 1) {
            const skipCount = this.commonMethod.lazyLoadingPagination(this.virtualScrollPayload?.skip_count, this.currentPage, this.lastPage);
            if (skipCount !== this.virtualScrollPayload.skip_count) {
                this.virtualScrollPayload.skip_count = skipCount;
                this.onLoadItems();
            }
        }
    }

    onTogglePin(index: number): void {
        this.commentsList[index].is_pinned = !this.commentsList[index].is_pinned;
        this.commonService.postCommentsPin(this.documentType, this.commentsList[index].id, { is_pinned: this.commentsList[index].is_pinned })
            .subscribe(response => {
                if (response) {
                    this.customToastrService.success(response?.message);
                }
            });
    }

    onDeleteDocument(index: number, dataItem: any, supportDocIndex: number): void {
        this.selectedParentIndex = index;
        this.selectedSupportDocumentId = dataItem?.id;
        this.selectedSupportDocumentIndex = supportDocIndex;
        this.commonMethod.openDeleteConformationDialog(CommonConstants.appConstants.deleteFileText);
    }

    deleteSupportDocument(): void {
        if (this.selectedParentIndex >= 0 && this.selectedSupportDocumentId && this.selectedSupportDocumentIndex >= 0) {
            this.isFormLoading = true;
            this.commonService.deleteSupportDocumentById(this.selectedSupportDocumentId)
                .pipe(finalize(() => this.isFormLoading = false))
                .subscribe(response => {
                    if (response) {
                        this.commonMethod.sendLoadDocumentStatusGrid(true);
                        this.customToastrService.success(response?.message);
                        this.commentsList[this.selectedParentIndex].support_documents.splice(this.selectedSupportDocumentIndex, 1);
                        this.selectedParentIndex = null;
                        this.selectedSupportDocumentId = null;
                        this.selectedSupportDocumentIndex = null;

                    }
                });
        }
    }

    onAddComment(dataItem: any): void {
        if (dataItem.bodyText) {
            this.isFormLoading = true;
            const addCommentFormPayload = _.cloneDeep(this.commonDataModel.addCommentFormPayload);
            const comment = this.commonMethod.commentValidation(dataItem.bodyText);
            if (comment) {
                addCommentFormPayload.body = comment;
                addCommentFormPayload.users = this.commonMethod.getTaggedUsers();
            }
            if (dataItem.uploadFile.fileName) {
                this.commonMethod.objectMapping(addCommentFormPayload.file, dataItem.uploadFile);
            } else {
                delete addCommentFormPayload.file;
            }
            this.commonService.postAddComment(this.documentType, dataItem.id, addCommentFormPayload)
                .pipe(finalize(() => this.isFormLoading = false))
                .subscribe(response => {
                    this.customToastrService.success(response?.message);
                    dataItem.bodyText = '';
                    dataItem.uploadFile = {};
                    this.onLoadItems();
                });
        }
    }

    onUpdateComment(dataItem: any): void {
        if (dataItem.body) {
            this.isFormLoading = true;
            let payload = _.cloneDeep(this.commonDataModel.addCommentFormPayload);
            const comment = this.commonMethod.commentValidation(dataItem.body);
            if (comment) {
                payload.body = comment;
                payload.users = this.commonMethod.getTaggedUsers();
            }
            if (dataItem.uploadFile.fileName) {
                this.commonMethod.objectMapping(payload.file, dataItem.uploadFile);
            } else {
                delete payload.file;
            }
            dataItem['body'] = comment;
            dataItem['isEditComment'] = false;
            this.commonService.postEditComment(dataItem.id, payload).subscribe(response => {
                this.customToastrService.success(response?.message);
                if (payload?.file) {
                    this.isFormLoading = true;
                    this.onLoadItems();
                } else {
                    this.isFormLoading = false;
                }
            });
        }
    }

    onEditComment(dataItem: any): void {
        dataItem['isEditComment'] = true;
    }

    onCancelComment(dataItem: any): void {
        dataItem['isEditComment'] = false;
        dataItem['uploadFile'] = _.cloneDeep(this.commonDataModel.fileModel);
    }

    onClearComment(index: number): void {
        this.commentsList[index].bodyText = '';
        this.commentsList[index].uploadFile = _.cloneDeep(this.commonDataModel.fileModel);
    }

    onShowResolveIssueForm(dataItem: any): void {
        const dialogRef = this.matDialog.open(ResolveIssueFormComponent, {
            panelClass: 'confirm-dialog-wrapper',
            hasBackdrop: false,
            data: {
                issueId: dataItem?.id,
                activeUserSourceList: this.activeUserSourceList
            }
        });
        dialogRef.afterClosed().subscribe((result: { confirmResult: boolean }) => {
            if (result.confirmResult) {
                this.commonMethod.sendLoadIssueGrid(true);
                this.onLoadItems();
            }
        });
    }

    async onChangeFile(dataItem: any, event: any) {
        try {
            const resultData = await this.commonMethod.uploadFileValidation(event);
            if (resultData.isFileValid) {
                this.commonMethod.objectMapping(dataItem['uploadFile'], resultData);
            } else {
                dataItem['uploadFile'] = _.cloneDeep(this.commonDataModel.fileModel);
            }
        } catch (error: any) {
            this.customToastrService.error(error);
        }
    }

    onCheckCommentsList(type: boolean) {
        const results = _.find(this.commentsList, ['is_pinned', type]);
        if (results && !this.isFormLoading && this.commentsList.length) {
            return false;
        }
        return true;
    }

    onShowSupportDocuments(dataItem: any) {
        if (dataItem?.length && (this.documentType !== CommonConstants.gridNameConstants.recentActivity && this.documentType !== CommonConstants.gridNameConstants.issuesGrid)) {
            return true;
        }
        return false;
    }

    onShowIssueFile(dataItem: any) {
        if (dataItem && this.documentType === CommonConstants.gridNameConstants.issuesGrid) {
            return true;
        }
        return false;
    }

    onShowResolveOption(dataItem: any) {
        if (this.loggedUserId === dataItem?.created_by?.id &&
            dataItem?.status !== CommonConstants.statusConstants.resolved &&
            this.documentType === CommonConstants.gridNameConstants.issuesGrid) {
            return true;
        }
        return false;
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}
